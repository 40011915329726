import React, { useEffect, useState } from "react";
import { Link, StaticQuery } from "gatsby";
import closeW from "../../assets/img/closeW.svg";
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const bounceAnimation = keyframes`${fadeInUp}`;

const DiscountInner = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  background: #3A557C;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  animation: 1s ${bounceAnimation};
  align-items: center;
  border-top-right-radius: 10px;
  max-width: 450px;

  @media (max-width: 450px) {
    display: none;
  }

  .left {
    background: rgba(255,255,255,0.07);
    border-radius: 10px;
    padding: 13px;
    height: fit-content;

    .top {
      font-family: NewKansas-Bold;
      font-size: 30px;
      color: #FFFFFF;
      display: block;
      white-space: nowrap;
    }

    .bot {
      font-family: NewKansas-Bold;
      display: block;
      font-size: 30px;
      color: rgba(255,255,255,0.70);
    }
  }

  .right {
    width: calc(100% - 147px - 20px);
    h5 {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #FFFFFF;
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 6px;
      margin-bottom: 15px;
    }

    a {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #293A56;
      text-align: center;
      width: 100%;
      background: #fff;
      text-decoration: none;
      display: block;
      padding: 8px 0;
      border-radius: 5px;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: 0;
    cursor: pointer;

    img {
      display: block;
    }
  }
`;



const Discount = ({price, heading, desc, cta, link}) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 7000);
  }, []);


  return (
    <>{isOpen &&
      <DiscountInner>
        <div className="left">
          <span className="top">{price}</span>
          <span className="bot">Poukaz</span>
        </div>

        <div className="right">
          <h5>{heading}</h5>
          <p>{desc}</p>
          <Link to={link}>{cta}</Link>
        </div>

        <button onClick={() => setIsOpen(false)}>
          <img src={closeW} alt="Zavřít" />
        </button>

      </DiscountInner>
    }</>
  );
  
}

export default Discount;


