import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import Reviews from "../../components/Reviews";
import JumbotronZaveti from "../../components/zaveti/JumbotronZaveti";
import ProuzekTelefon from "../../components/ProuzekTelefon";
import Podpora from "../../components/zaveti/Podpora";
import ZavetCTA from "../../components/zaveti/ZavetCTA";
import Img from "gatsby-image"

import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import JumbotronV2 from "../../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";
import Gdpr from "../../assets/img/gdpr.svg";
import Check from "../../assets/img/willCheck.svg";

// import Proces4 from "../../assets/img/willProces4.svg";
import PrepaidFeature1 from "../../assets/img/prepaidFeature1.svg";
import PrepaidFeature2 from "../../assets/img/prepaidFeature2.svg";
import PrepaidFeature3 from "../../assets/img/prepaidFeature3.svg";
import prepaidProces1 from "../../assets/img/prepaidProces1.svg";
import prepaidProces2 from "../../assets/img/prepaidProces2.svg";
import prepaidProces3 from "../../assets/img/prepaidProces3.svg";
import prepaidProces4 from "../../assets/img/prepaidProces4.svg";
import RecenzeV2 from "../../components/brandingV2/RecenzeV2";
import WillInfo from "../../components/zavetiV2/willInfo";
import Proces from "../../components/brandingV2/Proces";
import BlogPreview from "../../components/brandingV2/BlogPreview";
import { useMediaQuery } from "react-responsive";
import CallButtonV2 from "../../components/brandingV2/CallButtonV2";
import NonprofitSmall from "../../components/brandingV2/NonprofitSmall";
import Intro from "../../components/brandingV2/Intro";
import FeaturesV2 from "../../components/brandingV2/FeaturesV2";
import PrepaidPricing from "../../components/brandingV2/PrepaidPricing";
import OneReview from "../../components/brandingV2/OneReview";
import CalendlyPanel from "../../components/brandingV2/CalendlyPanel";
import FinalCta from "../../components/brandingV2/FinalCta";
import picFinalCta from "../../assets/img/willFinalCta.svg";
import Discount from "../../components/brandingV2/Discount";



const faqData = [
  {
    otazka: "Co se stane, pokud za pár let přestanete fungovat?",
    odpoved: "Každá zaplacená částka za pohřeb je držená ve striktně odděleném účtu od našeho provozního fungování (pod vaším jménem) kde se zhodnocuje pro garanci zachování ceny a předcházení inflace. K vašim penězům tak nikdo nemá přístup až do bodu vašeho úmrtí, kdy se s nimi může pracovat. Nikdy se z ní do té doby nebudou platit žádné náklady ani jiné závazky. Ve velice nepravděpodobné situaci, kdy by naše společnost skončila, vám bude částka vkladu vrácena a nebo přesunuta jiné pohřební službě na vypravení pohřbu podle vašeho přání."
  },
  {
    otazka: "Jak zjistíte, že došlo k úmrtí?",
    odpoved: "V rámci naší služby obdržíte manuál a příslušenství pro usnadnění oznámení o vašem úmrtí. Zákazníkům potom doporučujeme informovat svou rodinu, přátele nebo zdravotnický personál o tom, že službu máte zakoupenou. Na cestách u sebe můžete nosit jednoduchou kartičku, podobně jako kartičku pojištěnce. Jakmile se o vašem úmrtí dozvíme, vstupuje naše společnost do role vypravitele a váš pohřeb začínáme řešit."
  },
  {
    otazka: "Jaký je postup po mém úmrtí?",
    odpoved: "Jakmile se dozvíme o vašem úmrtí, o vše se postaráme. Jako první citlivě obeznámíme vaši rodinu, přátele a vybrané známé. Připravíme parte, zajistíme převoz vašeho těla do zařízení pohřební služby a dál budeme postupovat podle vašich přání na organizaci pohřbu. Po kremaci předáme vaši urnu s popelem vybrané osobě nebo popel rozptýlíme podle vašich instrukcí. Vaší rodině budeme i nadále k dispozici, pokud by potřebovali jakkoliv pomoci. Například při dědickém řízení, komunikací s pojišťovnou, prodejem nemovitostí, nebo rušení digitálních účtů."
  },
  {
    otazka: "Co když mám zvláštní požadavky na svůj pohřeb?",
    odpoved: "Rádi vám splníme každý požadavek, který budete mít. Ozvěte se nám prosím na podpora@goodbye.cz a společně pro vás vymyslíme individuální řešení."
  },
  {
    otazka: "Co když se přestěhuji?",
    odpoved: "Naše pohřební služba má pobočky po celé České republice v rámci desítek měst. Pohřeb podle vašeho přání vám tak bez problému vypravíme kdekoliv. Pokud máte v plánu se přestěhovat do zahraničí, doporučujeme vám si zkontrolovat, jestli vaše pojistka obsahuje repatriaci (převoz těla do ČR). Pokud ne, tuto částku vám rádi přidáme do našeho balíčku jako poplatek navíc."
  },
  {
    otazka: "Co když zemřu v zahraničí?",
    odpoved: "Většina cestovních pojištění už dnes obsahuje i náklady za repatriaci (převoz těla do ČR). Pokud toto v rámci vašeho pojištění nemáte, rádi vám tuto částku přidáme do našeho balíčku jako poplatek navíc. S první nebo druhou variantou se o vás v případě vašeho úmrtí bez problému postaráme. Zařídíme převoz vašeho těla a veškerou dokumentaci s tím spojenou. Jakmile bude tělo v ČR, pokračujeme podle vámi stanovených instrukcí."
  },
  {
    otazka: "Můžu si pohřeb předplatit na splátky?",
    odpoved: "Ano. Pohřeb můžete předplatit buď celou částkou a nebo na splátky až po dobu 5 let. Pokud k úmrtí dojde před splacením celé částky, má kontaktní osoba (nejčastěji rodina nebo kamarád) na výběr ze 2 možností: A) Doplatit zbývající částku. B) Nechat si peníze vynahrazené na pohřeb vrátit a vypravit pohřeb sám."
  },
  {
    otazka: "Nemám rodinu. Kdo dá pozor, že vše proběhne dle domluvy?",
    odpoved: "Jakožto koncesovaná pohřební služba podléháme důsledným kontrolám Ministerstva pro místní rozvoj, které dohlíží na správné plnění podstaty této služby. Po vašem úmrtí musíme identicky postupovat podle instrukcí, které budou jasně zaznamenány přímo do smlouvy s vámi. Na celý proces také dohlíží vámi zvolená kontaktní osoba."
  },
  {
    otazka: "Jsem sám. Co se stane s mým popelem po pohřbu?",
    odpoved: "S urnou s popelem budeme nakládat přesně podle vašeho přání. Urnu můžeme například rozptýlit, zasadit strom, nebo uložit na místě, které je vám blízké (se souhlasem majitele nebo provozovatele pozemku). S naší službou tak máte jistotu, že se o váš pohřeb nepostará obec v rámci sociálního pohřbu a vaše ostatky tak neskončí ve společném hrobě."
  },
  {
    otazka: "Mění se cena služby podle mého věku?",
    odpoved: "Ne, nemění. Za cenu, kterou zaplatíte dnes, vám garantujeme službu, kterou dostanete kdykoliv v budoucnu. Vyhnete se tak silnému zvyšování cen,které tento obor posledních několik let postihuje."
  },
  {
    otazka: "Jdou všechny peníze, které zaplatím, na můj pohřeb?",
    odpoved: "Služba, kterou si u nás koupíte, začíná dnem podpisu smlouvy. To znamená, že musíme nejprve státu zaplatit daň z přidané hodnoty z celkové částky. Celková částka také rovnou obsahuje i náš zřizovací poplatek, ze kterého se hradí veškerá administrativa spojená se zařízením služby. Peníze ihned po zaplacení putují do striktně odděleného účtu od našeho provozního financování kde s nimi nakládáme způsobem zamezujícím k jejich znehodnocení. 100% z této částky bude potom využito na pokrytí nákladů při vypravování vašeho pohřbu."
  },
  {
    otazka: "Můžu si svůj plán změnit, pokud jsem ho už zaplatil?",
    odpoved: "Ano, ozvěte se nám a rádi vám vše pomůžeme změnit přesně podle vašich přání."
  },
  {
    otazka: "Co když budu chtít od smlouvy odstoupit?",
    odpoved: "Žádný problém. Pokud se rozhodnete odstoupit během prvních 30 dnů od podepsání, okamžitě vám vrátíme všechny peníze, které jste nám zaplatili a vše stornujeme. Pokud se rozhodnete službu zrušit například za pár let, vrátíme vám celou částku poníženou o zřizovací poplatky."
  },
  {
    otazka: "Dostanu s vámi podepsanou smlouvu, kterou si můžu uložit?",
    odpoved: "Ano. V jedné smlouvě je transparentně popsaná celá naše služba a všechny závazky naší společnosti. Smlouvu standardně podepisujeme elektronicky podle standardu eIDAS. Originál smlouvy s digitální známkou vám okamžitě pošleme do e-mailu. Pravost si potom můžete sami zkontrolovat na České poště."
  },
  {
    otazka: "Kde najdu důkladný rozpis služeb, které jsou v mém balíčku?",
    odpoved: "Celou důkladně rozepsanou službu najdete transparentně po vyplnění poptávkového formuláře na našem webu."
  },
  {
    otazka: "Musím já nebo moji blízcí zaplatit nějaké (skryté) poplatky navíc?",
    odpoved: "Ne, jakmile bude služba plně uhrazena, nic dalšího už platit nebudete. Kompletní transparentnost bereme jako jeden z hlavních pilířů fungování naší pohřební služby."
  },
  {
    otazka: "Jaké jsou rozdíly předplaceného pohřbu oproti pojištění?",
    odpoved: "Několik pojišťoven dnes nabízí pokrytí nákladů za pohřeb jako výhodu v životním pojištění. Tyto peníze se ale k rodině často dostanou až několik týdnů po pohřbu. Rodina musí také zůstat vypravitelem a stále zařizovat administrativu spojenou se zařizováním pohřbu. U nás to funguje jinak: my jsme ti, co znají vaše přání a požadavky a my jsme vypravitelem. Současně máme také okamžitě přístup k vaším finančním prostředkům. Vaší rodinu tedy tímto způsobem ušetříte všem problémům a stresu s vypravením vašeho posledního rozloučení."
  },
];



let structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Předplacený pohřeb",
  "brand":"Goodbye.cz",
  "image":"https://goodbye.cz/ogImage.png",
  "description": "Předplaťte si pohřeb jednoduše online nebo přes telefon. Spolehlivě a za transparentní cenu. Ušetřete svým nejbližším starosti a stres z plánování pohřbu.",
  "aggregateRating": {
    "@type":"AggregateRating",
    "bestRating": "5",
    "ratingCount": 59,
    "ratingValue": 4.94,
    "worstRating": "1"}
};

const PredplaceneInner = styled.div`
  .leftJumbo {
    max-width: 700px !important;


    h1 {
      font-size: 50px !important;
      line-height: normal !important;
      margin-bottom: 20px !important;

      @media (max-width: 600px) {
        font-size: 35px !important;
        margin-bottom: 15px !important;
      }

      @media (max-width: 430px) {
        font-size: 30px !important;
      }

      @media (max-width: 340px) {
        font-size: 27px !important;
      }
    }
    
    h2 {
      font-family: Visuelt-Regular !important;
      font-size: 27px !important;
      color: #394B5C !important;
      line-height: normal !important;
      margin-top: 0 !important;

      @media (max-width: 600px) {
        color: rgb(102, 102, 102) !important;
        font-size: 22px !important;
      }

      @media (max-width: 430px) {
        font-size: 20px !important;
        margin-bottom: 0px !important;
      }
    }

    ul {
      margin-top: 35px;
      margin-bottom: 40px;

      @media (max-width: 430px) {
        margin-top: 25px;
        margin-bottom: 30px;
      }

      li {
        list-style: none;
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        @media (max-width: 430px) {
          margin-bottom: 20px;
        }

        img {
          margin-right: 10px;

          @media (max-width: 430px) {
            margin-right: 13px;
          }
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #394B5C;
          line-height: 28px;

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }

    .buttons {
      a {
        @media (max-width: 400px) {
          width: calc(100% - 4px - 44px);
        }

        @media (max-width: 360px) {
          width: calc(100% - 4px);
        }
      }

      .linkButton a {
        @media (max-width: 400px) {
          width: calc(100% - 48px);
        }

        @media (max-width: 360px) {
          width: calc(100% - 40px);
        }
      }
    }

    .rating {
      p {
        @media (max-width: 400px) {
          font-size: 16px;
        }
      }
    }
  }

  .rightJumbo {
    .obrazek {
      margin: -50px auto;

      @media (max-width: 1140px) {
        height: 290px !important;
        width: 300px !important;
      }

      @media (max-width: 899px) {
        height: 180px !important;
        width: 300px !important;
        margin: -30px;
        margin-bottom: 10px;
      }

      @media (max-width: 600px) {
        margin: -30px;
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
  }

  .prepulovac {
    max-width: 1150px;
    border: 0;
    border-top: 1px solid #D8D8D8;
    width: calc(100% - 100px);
    margin: 0 auto;
    border-style: solid;

    @media (max-width: 899px) {
      max-width: 700px;
      width: calc(100% - 50px);
    }
  }

`;

const PredplacenyPohreb = ({ data }) => {
  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });

  return (
    <Layout
      customCtaLink={"/predplaceny-pohreb/formular/"}
      subMenuItems={[
        {
          name: "Závěti",
          url: "/zaveti/",
        },
        {
          name: "Digitální archiv",
          url: "/digitalni-archiv/",
        },
        {
          name: "Předplacený pohřeb",
          url: "/predplaceny-pohreb/",
        },
      ]}
    >
      <SEO
        title="Předplacený pohřeb: Plánujte dopředu, spolehlivě | Goodbye.cz"
        customTitle
        description="Předplaťte si pohřeb jednoduše online nebo přes telefon. Spolehlivě a za transparentní cenu. Ušetřete svým nejbližším starosti a stres z plánování pohřbu."
        image="/predplacenyOg.png"
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      <Discount
        price="1 000 Kč"
        heading="Zvýhodněná nabídka"
        desc='Nezávazně poptejte předplacený pohřeb do konce tohoto týdne a získejte slevu 1 000 Kč. Stačí zadat kód "AKCE1000" v poptávkovém formuláři.'
        cta="Uplatnit kupón"
        link="/predplaceny-pohreb/formular/"
      />

      <PredplaceneInner>
        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={"Předplacený pohřeb"}
          customPerex={<>
            <h2>Jednoduché a dostupné rozloučení podle vás</h2>
            <ul>
              <li>
                <img src={Check} alt="" />
                <span>Předplaťte si pohřeb jednoduše online nebo přes telefon</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Za pár minut máte hotovo, spolehlivě a za transparentní cenu</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Plánujte dopředu a ušetřete svým nejbližším starosti a stres z plánování pohřbu</span>
              </li>
            </ul>
          </>}
          hideSecondHeading
          reviewDesc={<>Nejlépe hodnocená pohřební služba za 2022 <span className="nowrapReview">(4,95/5 průměrné hodnocení)</span></>}
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Předplacený pohřeb", url: "/predplaceny-pohreb/"}
              ]}
            />
          }
          customCta={[
            <LinkButtonV2 to="/predplaceny-pohreb/formular/" arrow text="Zjistit cenu podle PSČ" />,
            <CallButtonV2 />
          ]}
        />


        <RecenzeV2 
          sklonovaneMesto={""}
          customH2={"Váš důvěryhodný partner při plánování do budoucna"}
        />

        <Intro 
          introH2={"Proč si předplatit pohřeb s námi?"}
          link="/predplaceny-pohreb/formular/"
          introText={<>
            Řešení úmrtí blízkého je možná ta nejtěží situace v našem životě, plná stresu, zmatku a smutku. Sami jsme se několikrát setkali s neempatickým jednáním tradičních pohřebních služeb, uspěchanými a pochmurnými obřady — vždy za neúměrnou cenu. <b>Tomu všemu se dá předejít pokud se člověk připraví dopředu.</b>
            <br/><br/>
            Založili jsme Goodbye s důrazem na osobní přístup a transparentnost. Jsme koncesovaná pohřební služba, která funguje po celé ČR.
            <br/><br/>
            Pomůžeme vám naplánovat vlastní pohřeb tak, aby po vašem odchodu blízcí nemuseli nic řešit ani platit. Všechny zodpovědnosti na sebe přebíráme my. Náš tým odborníků bude současně vaší rodině k dispozici po celou dobu truchlení a dědického řízení — pokud by s něčím potřebovali pomoct. To vše za jednu neměnnou cenu.
          </>}
        />

        <hr className="prepulovac"/>

        <FeaturesV2
          customH2="Klid v duši a jistota, že mám vše zařízeno"
          desc="Nadále si užívejte života s jisotou, že váš odchod proběhne bez zbytečného stresu navíc pro ty, které máte rádi."
          text1="Mějte jistotu, že vaše poslední přání budou respektována a uskutečněna. Bez zbytečných zmatků a nedorozumění."
          text2="Ochraňte svou rodinu, partnera nebo blízké přátele před obtížnými rozhodnutími když právě truchlí."
          text3="Vyhněte se zdražování cen. Za poslední 2 roky stouply o 42 %. Za cenu, kterou zaplatíte dnes, vám vypravíme pohřeb i za 10 let."
          heading1="Ujasněte si svá poslední přání"
          heading2="Ušetřete vaší rodině starosti navíc"
          heading3="Stejná cena i za 10 let"
          ilustrace1={PrepaidFeature1}
          ilustrace2={PrepaidFeature2}
          ilustrace3={PrepaidFeature3}
        />

        <Proces
          h2="Jak to funguje?"
          procesDesc={"Při tvorbě této služby jsme se inspirovali zahračními pohřebními ústavy. V Anglii si svůj pohřeb předplácí 40 % lidí. Tento ověřený model jsme upravili podle tuzemských norem a přinesli k nám do ČR."}
          cutomTabs={[
            {
              num: 1,
              pic: prepaidProces1,
              heading: "Předplaťte si pohřeb online nebo přes telefon",
              desc: "Vyberte si rozloučení, které je vám blízké. Cenovou nabídku dostanete okamžitě. Za pár minut máte hotovo.",
            },
            {
              num: 2,
              pic: prepaidProces2,
              heading: "Peníze bezpečně uložíme na oddělený účet",
              desc: "Po odečtení zřizovacího poplatku uložíme vaše peníze na oddělený účet od běžného provozu naší společnosti, kde zůstanou.",
            },
            {
              num: 3,
              pic: prepaidProces3,
              heading: "Kontaktujeme blízké a vypravíme pohřeb",
              desc: "Zajistíme důstojné rozloučení podle vašich přání. Mezi tím vyřešíme veškerou dokumentaci a kontaktujeme blízké.",
            },
            {
              num: 4,
              pic: prepaidProces4,
              heading: "Popel předáme rodině nebo rozprášíme",
              desc: "Po pohřbu vrátíme popel vaší rodině nebo ho necháme rozptýlit. Vašim blízkým budeme nablízku po celou dobu truchlení.",
            },
          ]}
        />

        <PrepaidPricing
          checklist={[
            "Sjednání do pár minut přes internet",
            "Sepsání závěti zdarma (volitelné)",
            "Manuál a příslušenství pro případ úmrtí",
            "Převoz vašeho těla kdekoliv po ČR",
            "Uhrazení poplatků zdravotním institucím",
            "Vypravení důstojné kremace bez obřadu",
            "Základní kremační rakev, rubáš",
            "Urna dle výběru",
            "Informování blízkých a tisk parte",
            "Poskytnutí administrativní a emoční podpory vaší rodině Péče+",
          ]} 
        />

        <OneReview
          text={<>Byli jsme se službami velice spokojeni: rychlost, profesionalita, ochota a vstřícnost. <span>Tento přístup byl pro nás v této těžké situaci skutečnou pomocí.</span></>}
          img={data.recenzeSample.childImageSharp.fluid}
          imgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Hana V."}
          zakaznikKdo={"Zákaznice pohřební služby"}
        />

        <CalendlyPanel />

        <FAQ data={faqData} />

        <FinalCta
          image={picFinalCta}
          imageAlt="Předplacený pohřeb"
          h2="Připravte se dopředu a odděchněte si"
          p="Sami si naplánujte svůj odchod podle vašich přání. Odlehčete starosti těm, které máte rádi. Již dnes můžete mít hotovo."
          ctaLink="/predplaceny-pohreb/formular/"
          ctaText="Předplatit pohřeb"
        />

        <BlogPreview prepaid />


      </PredplaceneInner>
    </Layout>
  );
}

export const query = graphql`
  query {
    jumboObrazek: file(relativePath: { eq: "prepaid.png" }) {
      childImageSharp {
        fixed(width: 450) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "prepaidSmall.png" }) {
      childImageSharp {
        fixed(width: 315) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice1Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default PredplacenyPohreb;


