import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "../pohrby/LinkButton";
import { useMediaQuery } from 'react-responsive';
import Check from "../../assets/img/peceCheck.svg";
import Jo from "../../assets/img/pricingCheck.svg";
import LinkButtonV2 from "./LinkButtonV2";
import RevealText from "../pohrby/RevealText";
import prepaidPricing1 from "../../assets/img/prepaidPricing1.svg";
import prepaidPricing2 from "../../assets/img/prepaidPricing2.svg";
import prepaidPricing3 from "../../assets/img/prepaidPricing3.svg";
import prepaidPricing4 from "../../assets/img/prepaidPricing4.svg";


const NaceneniInner = styled.div`
  .naceneni {
    position: relative;
    margin: 70px auto;
    max-width: 1150px;
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      display: block;
      width: calc(100% - 50px);
      max-width: 700px;
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    .naceneniInner {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 899px) {
        display: block;
      }

      .left {
        max-width: 650px;
        width: calc(100% - 440px);

        @media (max-width: 950px) {
          width: calc(100% - 450px + 70px);
        }

        @media (max-width: 899px) {
          width: 100%;
          max-width: 100%;
          margin-bottom: 40px;
        }

        .top {
          display: flex;
          align-items: middle;
          justify-content: space-between;

          div {
            width: calc(100% - 200px - 40px);
            max-width: 420px;

            @media (max-width: 1100px) {
              max-width: none;
              width: calc(100%);
            }
          }

          img {
            width: 200px;

            @media (max-width: 1100px) {
              display: none;
            }
          }
        }

        h2 {
          font-family: NewKansas;
          font-size: 36px;
          color: #2c4668;

          @media (max-width: 899px) {
            font-size: 32px;
            margin-bottom: 25px;
          }

          @media (max-width: 430px) {
            font-size: 28px;
            line-height: 35px;
          }
        }

        p {
          margin-top: 35px;
          margin-bottom: 10px;
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #394B5C;
          line-height: 28px;

          @media (max-width: 899px) {
            margin-bottom: 0;
          }

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 30px;
            margin-bottom: 0;
          }
        }

        ul {
          margin-top: 30px;
          padding-bottom: 35px;
          padding-top: 35px;
          border-bottom: 1px solid #E1E1E1;
          border-top: 1px solid #E1E1E1;
          margin-bottom: 35px;

          @media (max-width: 899px) {
            margin-top: 40px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            margin-top: 30px;
          }

          li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              margin-right: 20px;
            }

            span {
              font-family: Visuelt-Regular;
              font-size: 18px;
              color: #000000;
              line-height: 24px;

              @media (max-width: 899px) {
                font-size: 17px;
              }

              @media (max-width: 370px) {
                font-size: 16px;
              }
            }
          }
        }

        .cenaWrapper {
          margin-bottom: 50px;

          .cenaInner {
            background: #FFFFFF;
            border: 2px solid #E1E1E1;
            border-radius: 5px;
            width: 100%;
            max-width: 500px;
            margin-bottom: 25px;
            
            .zakladni {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #000000;
              text-align: center;
              padding-top: 10px;
              padding-bottom: 10px;
              background: #E1E1E1;
            }

            .cena {
              padding: 20px 0;

              p {
                margin: 0 auto;

                &:first-child {
                  font-family: Visuelt-Medium;
                  font-size: 27px;
                  color: #000000;
                  text-align: center;
                  padding-bottom: 15px;
                  border-bottom: 1px solid #D8D8D8;
                  margin-bottom: 15px;
                  width: fit-content;

                  @media (max-width: 899px) {
                    font-size: 22px;
                  }

                  @media (max-width: 430px) {
                    font-size: 20px;
                  }

                  span {
                    font-family: Visuelt-Regular;
                    color: #727272;
                    font-size: 18px;

                    @media (max-width: 899px) {
                      font-size: 16px;
                    }

                    @media (max-width: 430px) {
                      display: block;
                      margin-top: 5px;
                    }
                  }
                }

                &:last-child {

                  font-family: Visuelt-Regular;
                  font-size: 18px;
                  color: #000000;
                  text-align: center;

                  span {
                    font-family: Visuelt-Bold;
                  }
                }
              }
            }
            
          }
        }

        .usetrite {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #265B30;
          background: #CEEECB;
          padding: 6px 15px;
          border-radius: 10px;
          display: block;
          width: fit-content;
          margin-bottom: 40px;
          line-height: 25px;
          margin-top: -25px;

          @media (max-width: 370px) {
            margin-bottom: 30px;
          }
        }

        .naMiru {
          font-family: Visuelt-Regular;
          font-size: 16px;
        }
      }

      .right {
        max-width: 400px;

        @media (max-width: 950px) {
          max-width: 330px;
        }

        @media (max-width: 899px) {
          width: 100%;
          max-width: 100%;
        }

        .bublina {
          background: #F4F3F0;
          border-radius: 20px;
          padding: 30px;
          width: calc(100% - 60px);
          margin-bottom: 30px;

          .reveal {
            margin: 0 auto;
            
            button {
              margin: 0 auto;
              width: fit-content;
              display: block;
              text-align: center;
            }

            p {
              margin: 0 auto;

              @media (max-width: 899px) {
                max-width: none !important;
              }
            }
          }

          img {
            display: block;
            margin: 0 auto;
            margin-bottom: 25px;

            @media (max-width: 430px) {
              height: 50px;
            }
          }

          h3 {
            font-family: Visuelt-Bold;
            font-size: 20px;
            color: #293A56;
            text-align: center;
            margin: 0px auto;
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #3D4B5B;
            text-align: center;
            line-height: 25px;
            margin: 15px auto;
            margin-bottom: 0;

            @media (max-width: 899px) {
              max-width: 500px;
            }
          }

          button {
            margin-top: 15px !important;

            img {
              @media (max-width: 430px) {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`;



const PrepaidPricing = ({checklist}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  return (
    <NaceneniInner>
      <div className="naceneni">
        <div className="naceneniInner">
          <div className="left">
            <div className="top">
              <div>
                <h2>Jednoduchá a transparetní cena</h2>
                <p>Na rozdíl od ostatních pohřebních služeb máme transparentní ceník a částka, na které se domluvíme, je vždy konečná.</p>
              </div>
              <img src={prepaidPricing4} alt="Ceník předplacené pohřby" />
            </div>
            


            <ul>
              {checklist.map(task => {
                return (
                  <li key={task}>
                    <img src={Jo} alt="" />
                    <span>{task}</span>
                  </li>
                );
              })}
            </ul>

            <div className="cenaWrapper">
              <div className="cenaInner">
                <div className="zakladni">Základní cena od</div>
                <div className="cena">
                  <p>990 Kč měsíčně <span>(vč. DPH) po dobu 5 let</span></p>
                  <p>nebo <span>39 900 Kč</span> jednorázově</p>
                </div>
              </div>
               
              <RevealText
                question="Cestujete často? Pojištění na převoz do ČR"
                answer="Pokud chcete, aby váš předplacený pohřeb obsahoval i případný převoz ze zahraničí, připočteme vám ke konečné ceně poplatek navíc. Pokud repatriace (převoz ze zahraničí) nebude nutná nebo ji už obsahuje vaše cestovní pojištění, celý poplatek po pohřbu vrátíme obmyšlené osobě."
              />
            </div>
            

            <span className="usetrite">Ušetřete až 10 000 Kč oproti jiným pohřebním službám a zafixujte si cenu proti inflaci.</span>

            <LinkButtonV2 to={"/predplaceny-pohreb/formular/"} arrow text="Získejte nabídku zdarma" />

            <p className="naMiru">Pokud máte vlastní a rozsáhlejší představu o plánovaném pohřbu - moc rádi vám vytvoříme cenovou nabídku na míru. Ozvěte se nám a vše s vámi probereme.</p>
          </div>

          <div className="right">
            
            <div className="bublina">
              <img src={prepaidPricing1} alt="30-denní garance vrácení peněz" />
              <h3>30-denní garance vrácení peněz</h3>
              <p>Chápeme, že předplacený pohřeb je velké rozhodnutí. Pokud si nákup rozmyslíte do 30 dnů, vrátíme vám peníze zpět.</p>
              {/* <RevealText
                question="Jak se máš?"
                answer="Ahoj já se mám fakt dobře. Ahoj já se mám fakt dobře. Ahoj já se mám fakt dobře. Ahoj já se mám fakt dobře. Ahoj já se mám fakt dobře. Ahoj já se mám fakt dobře."
              /> */}
            </div>

            <div className="bublina">
              <img src={prepaidPricing2} alt="Splátky až na 5 let" />
              <h3>Splátky až na 5 let</h3>
              <p>Pokud nechcete zaplatit celou částku najednou, nabízíme možnost splátek až po dobu 5 let.</p>
              <RevealText
                question="Co když zemřu před splacením?"
                answer={<>Pokud k úmrtí dojde před splacením celé částky, má obmyšlená osoba (nejčastěji rodina nebo kamarád) na výběr ze 2 možností:<br/>A) Doplatit zbývající částku.<br/>B) Nechat si peníze vynahrazené na pohřeb vrátit a vypravit pohřeb sám.</>}
              />
            </div>

            <div className="bublina">
              <img src={prepaidPricing3} alt="Vaše peníze jsou v bezpečí" />
              <h3>Vaše peníze jsou v bezpečí</h3>
              <p>Vaše peníze budou uloženy na oddělený účet, který nemá nic společného s běžným provozem naší společnosti. Můžete si být jisti, že zůstanou v bezpečí (ať se s Goodbye stane cokoli).</p>
              <RevealText
                question="Jak vypadá smlouva?"
                answer="Veškerou dokumentaci jsme připravovali s experty na obchodní a spotřebitelské právo. Smlouva je volně k dipozici na prohlédnutí v naší aplikaci po vyplnění poptávky."
              />
            </div>
            
          </div>
        </div>
      </div>
    </NaceneniInner>
  );
}

export default PrepaidPricing;


